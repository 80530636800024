import React, { Component } from 'react';
import propTypes from 'prop-types';

class Logo extends Component {

  static propTypes = {
    white: propTypes.bool
  }

  static defaultProps = {
    white: false
  }

  render() {
    const { white } = this.props;
    if (white) return <img src="/assets/img/logo/778x243_White.png" width="123" height="39" alt="Linknow" />;
    return <img src="/assets/img/logo/778x243_Dark.png" width="123" height="39" alt="Linknow" />;
  }
}

export default Logo;
